/**
 * Styles related to Callout Boxes
 *
 */

/* =Callout Boxes
----------------------------------------------------------*/

.callout {
  margin: 40px 0;
  padding: 30px;
  border: 1px solid $wcm-border-gray;
}

.callout__title {
  font-family: $wcm-bold;
  font-size: 40px;
  line-height: 42px;
  text-align: center;
  margin: 20px 0;
  color: $wcm-dark-gray;
}

.callout__title a {
  &:after {
    content: '\e80d';
    color: $wcm-bright-orange;
    padding-left: 10px;
    font-size: 60%;
    vertical-align: top;
    @include fontello();
    text-decoration: none;
    line-height: 1.5;
  }
}

.callout__description {
  font-weight: 300;
  font-size: 20px;
  line-height: 32px;
  color: $wcm-med-gray;
  text-align: center;
  margin: 0 auto 30px;
}

.callout__action {
  text-align: center;
}

.callout__action .btn--wcm {
  font-size: 16px;
  width: auto;
  display: inline-block;
  padding: 6px 42px;
}

/* =Tools Callouts
----------------------------------------------------------*/

.page-tools #main-content .panel-pane {
  margin: 0 0 30px;
  padding: 30px;
  border: 1px solid $wcm-border-gray;
  h3 {
    margin-top: 0;
  }
}
 
 
/**
 * Styles related to the Locations
 *
 */

 /* =Location Detail
 ----------------------------------------------------------*/

.field-wcm-loc-image {
  img {
    max-width: none;
    width: 100%;
  }
}
 
.wcm-location__meta > div {
  padding: 0 30px;
}
 
.wcm-location__trial-cta {
  padding: 20px 0 0; 
  .btn--wcm {
    width: auto;
    padding: 10px 20px;
    &:before {
      content: '';
    }
    &:hover:before {
      top: 10%;
      content: '';
    }
  }
}

.location-featured-trials {
  h2 {
    margin: 20px 0;
  }
}

.location-featured-trial__title {
  font-size: 13px;
  line-height: 18px;
  color: #000;
  border: 1px solid #ddd;
  border-width: 1px 0 0;
  cursor: pointer;
  margin: 0;
  padding: 15px 55px 15px 10px;
  font-weight: 600;
  a {
    border: none;
    &:after {
    content: '\e80d';
    color: $wcm-bright-orange;
    padding-left: 10px;
    font-size: 60%;
    vertical-align: middle;
    @include fontello();
    text-decoration: none;
    line-height: 1.5;
    }
  }
}

.view-all-trials-cta {
 .btn--wcm {
   margin: 0 0 30px;
   width: auto;
   padding: 10px 20px;
   &:hover {
     &:before {
       top: 10%;
     }
   }
 }
}

/* =Location List
----------------------------------------------------------*/

.location-teaser {
  position: relative;
  margin: 0 0 30px;
  overflow: hidden;
  > a {
    display: block;
    border: none;
  }
}

.location-teaser .mask {
  position: absolute;
  top: 15px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  z-index: 9;
  background: rgba(0,0,0,0.6);
  border: 2px solid $wcm-border-gray;
}

.location-teaser__title {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0%, -50%);
  text-align: center;
  font-family: $wcm-bold;
  font-size: 28px;
  line-height: 1.3;
  color: $wcm-white;
  z-index: 10;
}

.location-teaser__title:after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 0 8px 8px;
    border-color: transparent transparent transparent $wcm-bright-orange;
    margin-left: 10px;
}

.location-teaser__image img {
  transition: all 0.3s ease 0s;
}

.location-teaser > a:hover {
  .location-teaser__image img {
    transform: scale(1.1);
  }
}


/**
 * Example Stub for creating WCMC Subthemes
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
/**
 * WCMC JCTO Subthemes
 *
 */
/* =Site-specific styles
----------------------------------------------------------*/
footer {
  padding-top: 0; }

#primary-nav .level-1 {
  width: 25%; }

.main-content {
  margin-bottom: 20px; }

.block-menu-block .content {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-gap: 15px;
  -moz-column-gap: 15px;
  column-gap: 15px; }

.cta-text p {
  font-size: 13px;
  font-size: 1.3rem;
  color: #413c3d;
  margin-right: 10px; }

.cta-text .lt-ie9 .cta-text p {
  margin-right: 3em; }

@media screen and (min-width: 985px) {
  .cta-text .cta-text p {
    margin-right: 3em; } }

.cta-text .lt-ie9 .cta-text p {
  margin-right: 25px; }

@media screen and (min-width: 600px) {
  .cta-text .cta-text p {
    margin-right: 25px; } }

.go-cta {
  background-color: #B31B1B;
  border: 3px solid #E0E0E0;
  border-radius: 50%;
  color: #FFFFFF;
  float: right;
  font-weight: bold;
  height: 25px;
  left: -10px;
  padding: 12px 10px 8px 11px;
  position: relative;
  text-transform: uppercase;
  top: -57px;
  width: 25px; }
  .go-cta:hover {
    color: #FFFFFF;
    background-color: #9d1818;
    text-decoration: none; }

/*ROW2*/
.cta-home-row2-left {
  width: 49%;
  height: 300px;
  padding: 0%;
  text-align: center;
  display: block;
  float: left;
  clear: left; }
  .cta-home-row2-left img {
    width: 550px; }

.cta-home-row2-right p, .cta-home-row2-left p {
  margin-top: 11px; }
  .cta-home-row2-right p a, .cta-home-row2-left p a {
    color: #69605d; }

.cta-home-row2-right {
  width: 49%;
  height: 300px;
  padding: 0%;
  text-align: center;
  display: block;
  float: right;
  clear: right; }
  .cta-home-row2-right img {
    width: 556px; }

/*ROW3*/
.homepage-news-image {
  margin: -18px 0px 26px 0px; }

.view-display-id-panel_pane_5 {
  width: auto;
  height: auto;
  padding: 0%;
  margin-top: 2%;
  display: inline-block;
  text-align: left;
  margin-right: 1%;
  float: left; }

.cta-home-row3-left {
  width: 32.6%;
  height: 275px;
  padding: 0%;
  margin-top: 2%;
  display: inline-block;
  text-align: center;
  margin: 2% 1% 0% 0%;
  float: left;
  position: static; }

.cta-home-row3-center {
  width: 32.6%;
  height: 275px;
  padding: 0%;
  margin-top: 2%;
  display: inline-block;
  text-align: center;
  margin-right: 1%;
  float: left;
  position: static; }

.cta-home-row3-right {
  width: 32.6%;
  height: 275px;
  padding: 0%;
  margin-top: 2%;
  display: inline-block;
  text-align: center;
  float: left; }

.cta-home-row3-left p a, .cta-home-row3-center p a, .cta-home-row3-right p a {
  color: #7F7F7F !important; }
  .cta-home-row3-left p a:after, .cta-home-row3-center p a:after, .cta-home-row3-right p a:after {
    content: '\e80d';
    color: #e87722;
    padding-left: 10px;
    font-size: 60%;
    vertical-align: top;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-decoration: none;
    line-height: 2.8; }

.cta-home-row3-left p, .cta-home-row3-center p, .cta-home-row3-right p {
  margin-top: 26px; }

/*Mobile and Tablet*/
@media screen and (max-width: 760px) {
  /*ROW2*/
  .cta-home-row2-left {
    width: 95%;
    height: 100%;
    padding: 2%;
    text-align: center;
    float: left; }
  .cta-home-row2-right {
    width: 95%;
    height: 100%;
    padding: 2%;
    text-align: center;
    float: right;
    clear: right; }
  /*ROW3*/
  .view-display-id-panel_pane_5 {
    width: 95%;
    height: 100%;
    padding: 2%;
    text-align: center;
    float: left; }
  .cta-home-row3-left {
    width: 95%;
    height: 100%;
    padding: 2%;
    text-align: center;
    float: left; }
  .cta-home-row3-center {
    width: 95%;
    height: 100%;
    padding: 2%;
    text-align: center;
    float: left; }
  .cta-home-row3-right {
    width: 95%;
    height: 100%;
    padding: 2%;
    text-align: center;
    float: left; } }

/* =============================================================================
~New JCTO trials styles
========================================================================== */
.form-submit, .ct_more_button, .ct_back_btn, .dndcinfo {
  border-radius: 5px;
  background-color: #cf4520;
  height: 40px;
  line-height: 20px;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 10px !important; }

.ct_more_button, .ct_back_btn {
  float: left;
  color: #fff;
  padding: 0 10px;
  text-transform: capitalize;
  line-height: 30px;
  height: 30px;
  width: 100%;
  clear: both;
  margin: 12px 0 20px; }

.dndcinfo {
  color: #fff;
  float: left;
  text-decoration: none;
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  margin: 10px 0 25px; }
  .dndcinfo:hover {
    text-decoration: none;
    color: #fff;
    background-color: #e7751d; }

.feat {
  color: #ffffff;
  font-size: 13px;
  font-weight: 400;
  text-transform: none;
  padding: 0 6px; }

.views-field-nothing span.field-content {
  padding-left: 0px !important; }

.view-id-clinical_trials div.field-content a {
  color: #69605d; }

ul.pager {
  float: left; }
  ul.pager li {
    margin-left: 0px;
    font-size: 15px;
    font-weight: 400; }
    ul.pager li a {
      color: #b31b1b;
      text-align: left; }
  ul.pager li.pager-ellipsis {
    display: none; }

.radix-layouts-sidebar h3 {
  color: #000;
  font-size: 16px;
  margin-top: 15px; }

.radix-layouts-sidebar .pane-entity-field .field {
  margin-bottom: 0; }

.radix-layouts-sidebar ul.links {
  margin-bottom: 0; }

.pane-uuid-f93e31c7-9f76-43dc-9f0e-624bc7bc64f9 {
  float: left;
  width: 100%; }
  .pane-uuid-f93e31c7-9f76-43dc-9f0e-624bc7bc64f9 h2 {
    float: left;
    clear: both; }
  .pane-uuid-f93e31c7-9f76-43dc-9f0e-624bc7bc64f9 p {
    float: right; }
    .pane-uuid-f93e31c7-9f76-43dc-9f0e-624bc7bc64f9 p .ct_back_btn {
      float: right; }
      .pane-uuid-f93e31c7-9f76-43dc-9f0e-624bc7bc64f9 p .ct_back_btn:hover {
        background-color: #e7751d;
        text-decoration: none;
        color: #fff; }

.pane-clinical-trials-panel-pane-1 {
  margin-left: 24px; }
  .pane-clinical-trials-panel-pane-1 .views-field-title {
    margin: 10px 0 7px; }
    .pane-clinical-trials-panel-pane-1 .views-field-title .field-content {
      text-decoration: none !important; }
    .pane-clinical-trials-panel-pane-1 .views-field-title a {
      font-size: 18px;
      font-weight: 400;
      line-height: 26px; }
  .pane-clinical-trials-panel-pane-1 .views-field {
    float: left;
    width: 100%; }
    .pane-clinical-trials-panel-pane-1 .views-field .views-label {
      float: left;
      font-size: 15px;
      font-weight: bold;
      line-height: 22px; }
    .pane-clinical-trials-panel-pane-1 .views-field .field-content {
      float: left;
      font-size: 15px;
      line-height: 22px;
      padding-left: 3px; }
  .pane-clinical-trials-panel-pane-1 .views-field-title .field-content {
    padding-left: 0px; }
  .pane-clinical-trials-panel-pane-1 .views-field-view-node .field-content {
    padding-left: 0px; }

.pane-views-exp-clinical-trials-page .views-exposed-widget {
  padding-top: 22px; }

.pane-views-exp-clinical-trials-page select {
  max-width: 540px;
  width: 100%;
  height: 50px;
  color: #301d1d;
  font-size: 15px;
  line-height: 50px;
  text-align: left;
  background: url("../images/arrowdown2x.png") no-repeat right 14px top 9px;
  background-size: 31px 30px;
  padding-right: 20px;
  box-sizing: border-box;
  border: 1px solid #aeaaaa;
  border-radius: 4px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-left: 8px;
  border: 1px solid #ddd; }

.pane-views-exp-clinical-trials-page label[for=edit-field-categoryn-tid] {
  display: none; }

.pane-views-exp-clinical-trials-page label {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px; }

.pane-views-exp-clinical-trials-page .asearch {
  margin-bottom: 17px;
  margin-top: 22px;
  float: left;
  width: 100%;
  cursor: pointer; }

.shs-select-level-2 {
  margin-top: 25px;
  margin-bottom: 25px; }

.pane-views-exp-clinical-trials-page-1 label {
  font-size: 13px;
  margin-top: 22px; }

.pane-views-exp-clinical-trials-page-1 label[for=edit-field-drugs-device-value] {
  margin-top: 0; }

.pane-views-exp-clinical-trials-page-1 select {
  max-width: 540px;
  width: 100%;
  height: 50px;
  color: #301d1d;
  font-size: 15px;
  line-height: 50px;
  text-align: left;
  background: url("../images/arrowdown2x.png") no-repeat right 14px top 9px;
  background-size: 31px 30px;
  padding-right: 20px;
  box-sizing: border-box;
  border: 1px solid #aeaaaa;
  border-radius: 4px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-left: 8px;
  border: 1px solid #ddd; }

.pane-views-exp-clinical-trials-page-1 #edit-field-categoryn-tid-wrapper {
  padding-top: 0px; }

.pane-views-exp-clinical-trials-page-1 #edit-combine {
  background-image: none; }

.pane-views-exp-clinical-trials-page-1 .views-exposed-widget {
  padding: 0px;
  float: left;
  clear: both; }

.pane-views-exp-clinical-trials-page-1 .views-widget-filter-field_study_type_tid {
  width: 100%; }

.pane-views-exp-clinical-trials-page-1 .views-widget-filter-field_categoryn_tid {
  display: none; }

.btmbar {
  border-bottom: 1px solid #E7E4E0;
  width: 100%;
  display: block; }

#edit-combine-wrapper,
#edit-field-categoryn-tid-wrapper,
.views-widget-filter-field_drugs_device_value,
.views-widget-filter-field_phase_value,
.views-widget-filter-field_study_type_tid,
.views-widget-filter-shs_term_node_tid_depth {
  width: 100%; }

#edit-combine {
  max-width: 540px;
  height: 50px;
  line-height: 50px;
  width: 100%;
  background: url("../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/search.svg") no-repeat;
  background-position: right 11px top 11px;
  background-size: 27px 27px; }
  #edit-combine::-webkit-input-placeholder {
    color: #301d1d; }
  #edit-combine:-moz-placeholder {
    /* Firefox 18- */
    color: #301d1d; }
  #edit-combine::-moz-placeholder {
    /* Firefox 19+ */
    color: #301d1d; }
  #edit-combine:-ms-input-placeholder {
    color: #301d1d; }

#views-exposed-form-clinical-trials-page h3 {
  margin-bottom: 15px;
  margin-top: 5px; }

.CT-form-footer {
  float: left;
  border-top: 1px solid #E7E4E0;
  width: 100%;
  padding-top: 15px;
  margin-top: 12px; }
  .CT-form-footer .views-exposed-widget {
    padding-top: 0px; }
    .CT-form-footer .views-exposed-widget input {
      margin-top: 0px !important; }
  .CT-form-footer p {
    line-height: 40px;
    height: 40px;
    text-align: left;
    margin: 0; }

#edit-field-primary-investigator-name-value-wrapper, #edit-field-status-tid-wrapper {
  display: none; }

#edit-field-categoryn-tid-wrapper {
  padding-top: 28px; }

/* --- SORT - Hiding Filters ---*/
.pane-views-exp-clinical-trials-page-2 .views-widget-filter-field_categoryn_tid,
.pane-views-exp-clinical-trials-page-2 .views-widget-filter-field_sponsor_tid,
.pane-views-exp-clinical-trials-page-2 .views-widget-filter-combine,
.pane-views-exp-clinical-trials-page-2 .views-widget-filter-field_study_type_tid,
.pane-views-exp-clinical-trials-page-2 .views-widget-filter-field_drugs_device_value,
.pane-views-exp-clinical-trials-page-2 .views-widget-filter-field_phase_value,
.pane-views-exp-clinical-trials-page-2 .views-widget-filter-shs_term_node_tid_depth,
.pane-views-exp-clinical-trials-page-2 .asearch {
  display: none; }

.pane-views-exp-clinical-trials-page-2 .viewalllink {
  display: none; }

.pane-views-exp-clinical-trials-page-2 .views-widget-sort-by {
  float: left;
  width: 100%;
  height: 39px;
  background-color: #EFEFEF;
  color: #000;
  padding-top: 0px; }
  .pane-views-exp-clinical-trials-page-2 .views-widget-sort-by label {
    float: left;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    font-size: 15px;
    line-height: 39px;
    font-weight: 400;
    padding: 0 20px 0 24px; }
  .pane-views-exp-clinical-trials-page-2 .views-widget-sort-by a {
    float: left;
    color: #000;
    font-size: 15px;
    line-height: 39px;
    padding: 0 20px; }
  .pane-views-exp-clinical-trials-page-2 .views-widget-sort-by a.active {
    font-weight: bold; }

.pane-views-exp-clinical-trials-page-2 .views-widget-sort-order {
  padding-top: 1em; }
  .pane-views-exp-clinical-trials-page-2 .views-widget-sort-order label[for="edit-sort-order"] {
    display: none; }
  .pane-views-exp-clinical-trials-page-2 .views-widget-sort-order a {
    color: #000;
    font-size: 14px; }
  .pane-views-exp-clinical-trials-page-2 .views-widget-sort-order a.active {
    font-weight: bold; }
  .pane-views-exp-clinical-trials-page-2 .views-widget-sort-order #edit-sort-order-asc {
    padding: 0 13px 0 24px;
    border-right: 1px solid #E7E4E0;
    float: left; }
  .pane-views-exp-clinical-trials-page-2 .views-widget-sort-order #edit-sort-order-desc {
    float: left;
    padding-left: 9px; }

.pane-views-exp-clinical-trials-page-2 .CT-form-footer {
  margin-left: 3.4458%;
  width: 96.5542%; }

#views-exposed-form-clinical-trials-page-1 .views-widget-sort-by {
  display: none; }

#views-exposed-form-clinical-trials-page-1 .viewalllink {
  display: none; }

#views-exposed-form-clinical-trials-page-1 .orfil {
  display: none; }

#views-exposed-form-clinical-trials-page-1 .views-widget-filter-combine label[for='edit-combine'] {
  display: none; }

.field-name-field-key-eligibility div.field-label {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #301d1d;
  border-top: 1px solid #E3E4E5;
  padding-top: 10px;
  margin-top: 10px; }

.radix-moscone .col-md-9 {
  border-left: 1px solid #E7E4E0; }

.pane-clinical-trials-panel-pane-2 {
  float: left;
  padding-bottom: 4px;
  margin-bottom: 14px;
  border-bottom: 1px solid #E7E4E0; }

#views-exposed-form-clinical-trials-page #edit-field-study-type-tid-wrapper,
#views-exposed-form-clinical-trials-page #edit-field-phase-value-wrapper,
#views-exposed-form-clinical-trials-page #edit-field-age-group-value-wrapper,
#views-exposed-form-clinical-trials-page #edit-field-drugs-device-value-wrapper {
  display: none;
  width: 100%; }

.pane-entity-field .field {
  margin-bottom: 20px; }

.pane-entity-field .field-label {
  font-size: 16px;
  font-weight: normal;
  text-transform: uppercase;
  margin-bottom: 3px; }

.pane-views-exp-clinical-trials-page-1 .asearch {
  display: none; }

.pane-views-exp-clinical-trials-page-1 .views-widget-filter-field_sponsor_tid_1,
.pane-views-exp-clinical-trials-page-1 .views-widget-filter-field_keywords_tid,
.pane-views-exp-clinical-trials-page-1 .views-widget-filter-field_categoryn_tid_1 {
  display: none; }

.views-submit-button input.form-submit {
  position: relative;
  width: 220px;
  margin: 0 auto;
  border-radius: 20px;
  border-width: 4px;
  font-size: 13px;
  color: #cf4520;
  background-color: transparent;
  border: 4px solid #e7751d;
  padding: 6px 20px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  white-space: nowrap;
  line-height: 1.666666667;
  -webkit-user-select: none; }
  .views-submit-button input.form-submit:hover {
    color: #fff;
    background-color: #e87722;
    border-color: #cf4520; }

.views-exposed-form .views-exposed-widget .form-submit {
  margin-top: 0; }

.views-reset-button .form-submit {
  color: #cf4520;
  position: relative;
  display: block;
  width: 220px;
  margin: 0 auto;
  border-radius: 20px;
  border-width: 4px;
  font-size: 13px;
  color: #cf4520;
  background-color: transparent;
  border: 4px solid #e7751d;
  padding: 6px 20px;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  white-space: nowrap;
  line-height: 1.666666667;
  -webkit-user-select: none; }
  .views-reset-button .form-submit:hover {
    color: #fff;
    background-color: #e87722;
    border-color: #cf4520; }

/* Text area */
div.caption {
  padding-bottom: 0;
  margin-bottom: 0;
  margin-top: 0; }
  div.caption div.caption-inner {
    border: none;
    padding-left: 0px;
    padding-bottom: 0;
    margin-bottom: 0; }
    div.caption div.caption-inner p {
      color: #69605d;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      padding-bottom: 0;
      margin-bottom: 0; }

.view-display-id-panel_pane_1 .view-footer {
  display: none; }

.norestitle {
  color: #301d1d;
  font-size: 25px;
  font-weight: 400;
  line-height: 32px;
  text-transform: none;
  margin-left: 24px;
  width: 100%;
  float: left; }

.pane-views-exp-clinical-trials-page-2 .ctools-auto-submit-click {
  display: none; }

.field-name-body img {
  padding: 10px; }

#user-login a {
  font-size: 13.6px; }

.cycle-prev, .cycle-next {
  background-image: none; }

#views-exposed-form-clinical-trials-page #edit-field-phase-value-wrapper,
#views-exposed-form-clinical-trials-page #edit-combine-wrapper,
#views-exposed-form-clinical-trials-page #edit-field-phase-auto-tid-wrapper {
  display: none; }

@media screen and (min-width: 985px) {
  #views-exposed-form-clinical-trials-page #edit-field-phase-auto-tid-wrapper {
    width: 100%; } }

.pane-views-exp-clinical-trials-page .asearch {
  display: none;
  margin-bottom: 17px;
  margin-top: 22px;
  float: left;
  width: 100%;
  cursor: pointer; }

.pane-views-exp-clinical-trials-page-1 .asearch {
  display: none; }

.pane-views-exp-clinical-trials-page-1 .views-widget-filter-field_sponsor_tid_1,
.pane-views-exp-clinical-trials-page-1 .views-widget-filter-field_keywords_tid,
.pane-views-exp-clinical-trials-page-1 .views-widget-filter-field_categoryn_tid_1 {
  display: none; }

.information-sidebar .view-id-clinical_trials .view-filters {
  display: none; }

.information-sidebar {
  font-size: 13px; }
  .information-sidebar .panels-ipe-portlet-wrapper {
    float: left;
    width: 100%; }
  .information-sidebar .views-field-field-categoryn, .information-sidebar .views-field-title {
    float: left;
    width: 100%; }

.pane-views-exp-clinical-trials-page-2 .views-widget-filter-field_categoryn_tid,
.pane-views-exp-clinical-trials-page-2 .views-widget-filter-field_sponsor_tid,
.pane-views-exp-clinical-trials-page-2 .views-widget-filter-combine,
.pane-views-exp-clinical-trials-page-2 .views-widget-filter-field_study_type_tid,
.pane-views-exp-clinical-trials-page-2 .views-widget-filter-field_drugs_device_value,
.pane-views-exp-clinical-trials-page-2 .views-widget-filter-field_phase_value,
.pane-views-exp-clinical-trials-page-2 .views-widget-filter-shs_term_node_tid_depth,
.pane-views-exp-clinical-trials-page-2 .views-widget-filter-field_phase_auto_tid,
.pane-views-exp-clinical-trials-page-2 .views-widget-filter-field_age_group_value,
.pane-views-exp-clinical-trials-page-2 .asearch,
.pane-views-exp-clinical-trials-page-1 .views-widget-sort-order,
.pane-views-exp-clinical-trials-page-1 .views-widget-sort-by {
  display: none; }

.pane-views-exp-clinical-trials-page-1 .select2-container {
  max-width: 291px;
  min-width: 291px;
  margin-bottom: 25px; }

.pane-views-exp-clinical-trials-page .select2-container {
  min-width: 78%;
  margin-bottom: 25px; }

.ui-autocomplete-input {
  width: 100%;
  display: block;
  margin: 10px 0;
  border: 1px solid #777; }

.ui-autocomplete {
  background-color: #eee; }
  .ui-autocomplete .lookup-term {
    font-weight: bold; }

.information-column h3 {
  text-align: left;
  font-size: 16px;
  color: #000; }

.information-column .feat {
  width: 100%; }

.information-column h4 {
  text-align: left; }

.contact-info p {
  text-align: left; }

.information-column .pane-clinical-trials-panel-pane-2 a:after {
  content: "";
  display: inline-block;
  position: relative;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #e67730;
  margin-left: 5px; }

.information-column .pane-clinical-trials-panel-pane-2 a.dndcinfo:after {
  display: none; }

.view-id-news.view-display-id-panel_pane_1 figure {
  float: left;
  width: 20%;
  margin: 18px 10px 0 0; }
  .view-id-news.view-display-id-panel_pane_1 figure img {
    width: 100%;
    max-width: 100%; }
  .view-id-news.view-display-id-panel_pane_1 figure figcaption {
    float: left;
    width: 100%;
    text-align: left; }

.view-id-news.view-display-id-panel_pane_1 .view-teaser {
  margin-bottom: 10px; }

#thumbnail-pager a > img {
  max-width: none;
  width: 146.5px;
  height: 82.6px; }

.thumbnail-controller .pager {
  margin: 30px 0; }

#two h3 a:after {
  line-height: 1.9; }

#three h3 {
  margin: 20px 0 10px; }
  #three h3 a:after {
    line-height: 1.9; }

.slide-image {
  max-height: 460px;
  overflow: hidden; }

.view-carousel .cycle-slideshow .carousel-slide .slide-text {
  background-color: #fff;
  padding: 15px 0;
  opacity: 0.85; }
  .view-carousel .cycle-slideshow .carousel-slide .slide-text p {
    padding-top: 18px;
    padding-right: 10px;
    color: #000; }
    .view-carousel .cycle-slideshow .carousel-slide .slide-text p a {
      color: #e87722; }
  .view-carousel .cycle-slideshow .carousel-slide .slide-text h2 a {
    color: #000; }

.view-carousel .thumbnail-controller .thumbnail-controller-wrap .thumbnail-controller-wrap-inner #thumbnail-pager a > img {
  width: 120px;
  height: auto; }

.brand__lockup img {
  max-width: 456px;
  display: block;
  margin: 0 auto; }

.ctools-toggle {
  display: none; }

.ctools-collapsible-container h2.pane-title {
  position: relative;
  font-size: 13px;
  line-height: 18px;
  color: #000;
  border: 1px solid #ddd;
  border-width: 1px 0 0;
  cursor: pointer;
  margin: 0;
  padding: 15px 55px 15px 10px;
  font-weight: 600;
  font-family: "open-sans", sans-serif; }
  .ctools-collapsible-container h2.pane-title:hover {
    background: #f7f7f7;
    color: #e87722; }
  .ctools-collapsible-container h2.pane-title:after {
    content: '-';
    color: #cf4520;
    width: 50px;
    display: block;
    position: absolute;
    top: 15px;
    right: 0;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    border-left: 1px solid #ddd; }

.ctools-collapsible-content {
  padding: 0 50px 0 10px; }

.ctools-collapsed h2.pane-title {
  color: #cf4520; }
  .ctools-collapsed h2.pane-title:after {
    content: '+'; }

.node-clinical-trial h1 {
  float: left;
  width: 100%; }

.pane-node-body h4 {
  margin-bottom: 20px; }

/* New Table Styles */
.conttable > thead > tr > th, .conttable > thead > tr > td,
.conttable > tbody > tr > th, .conttable > tbody > tr > td,
.conttable > tfoot > tr > th, .conttable > tfoot > tr > td {
  border: 1px solid #767677; }

.conttable tbody > tr > td, .conttable tbody > tr > th, .conttable, table.conttable > tbody > tr:nth-child(odd) > td {
  border-right: none;
  border-left: none; }

table {
  width: 100%;
  border: 1px solid #777; }

table > thead > tr > th,
table > thead > tr > td,
table > tbody > tr > th,
table > tbody > tr > td,
table > tfoot > tr > th,
table > tfoot > tr > td {
  border: 1px solid #777;
  padding: 11px; }

table > tbody > tr:nth-child(odd) > td, table > tbody > tr:nth-child(odd) > th {
  background-color: #fff;
  border: 1px solid #777; }

table > tbody > tr:first-child > td {
  border-top: 1px solid #dbdcdd; }

table.noheader > tbody > tr:first-child > td {
  border-top: 1px solid #777; }

.pane-node-title h1 {
  margin-bottom: 20px; }

.panel-pane.pane-custom.pane-1 {
  width: 100%;
  float: left; }

a.noafter:after {
  display: none !important; }

/* =============================================================================
   ~Diagrams
   ========================================================================== */
#investigator-diagram {
  margin: 0 0 20px;
  position: relative; }
  #investigator-diagram img {
    width: 100%;
    margin-bottom: 20px; }

.diagram-link {
  display: block;
  position: absolute; }

.contracts {
  width: 17.08333%;
  height: 20.3703%;
  top: 28.808%;
  left: 9.79%; }

.study-activation {
  width: 28.7234%;
  height: 33.3333%;
  top: 16.2962%;
  left: 28.2978%; }

.finance {
  width: 17.0212%;
  height: 20.3703%;
  top: 57.1923%;
  left: 18.2978%; }

.integrity {
  width: 17.0212%;
  height: 20.3703%;
  top: 50%;
  left: 57.0212%; }

@media screen and (min-width: 992px) {
  .cycle-slideshow {
    max-height: 460px; } }

@media screen and (min-width: 1200px) {
  .cycle-slideshow {
    max-height: none; } }

#edit-submit-clinical-trials {
  background-image: url("../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/submit.svg");
  background-repeat: no-repeat;
  background-position: right 11px top 3px;
  background-size: 27px 27px; }
  #edit-submit-clinical-trials:hover {
    background-image: none; }

#edit-reset, .views-reset-button > .form-submit {
  background-image: url("../images/reset-icon.svg");
  background-repeat: no-repeat;
  background-position: right 11px top 3px;
  background-size: 27px 27px; }
  #edit-reset:hover, .views-reset-button > .form-submit:hover {
    background-image: none; }

h2.tagline {
  font-size: 15px;
  color: #818182;
  text-align: center;
  width: 100%;
  margin: 25px 0 23px;
  font-family: "open-sans", sans-serif; }

.pane-node-field-protocolid .field-label {
  text-transform: none;
  font-weight: bold; }

.pane-node-field-featured-image.news-featured-image .hero-image img {
  width: auto; }

.pane-node-field-featured-image.news-featured-image figcaption {
  float: none;
  width: auto;
  text-align: left; }

span[aria-labelledby="select2-edit-shs-term-node-tid-depth-select-4-container"] {
  display: none !important; }

.pane-trial-categories-panel-pane-1 .hiddencats {
  display: none; }

a.dison {
  color: #000;
  font-weight: bold;
  font-size: 18px; }

label[for=edit-shs-term-node-tid-depth] {
  font-weight: bold; }

.pane-node-field-protocolid h3,
.pane-node-field-sponsor-protocol-id h3,
.pane-node-field-clinicaltrials-gov-protoco h3 {
  color: grey;
  margin-top: 10px; }

.select2-container--default .select2-results > .select2-results__options {
  max-height: 367px; }

/* Styles for SOLR Search */
.view-clinical-trial-search .views-field-title {
  margin: 10px 0 7px; }
  .view-clinical-trial-search .views-field-title .field-content {
    text-decoration: none !important; }
  .view-clinical-trial-search .views-field-title a {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px; }

.view-clinical-trial-search .views-field {
  float: left;
  width: 100%; }
  .view-clinical-trial-search .views-field .views-label {
    float: left;
    font-size: 15px;
    font-weight: bold;
    line-height: 22px; }
  .view-clinical-trial-search .views-field .field-content {
    float: left;
    font-size: 15px;
    line-height: 22px;
    padding-left: 3px; }

.view-clinical-trial-search .views-field-title .field-content {
  padding-left: 0px; }

.view-clinical-trial-search .views-field-view-node .field-content {
  padding-left: 0px; }

.solr-search-header {
  background-color: #f7f7f7;
  margin-bottom: 20px; }
  .solr-search-header .pane-node-title h1 {
    color: #000000;
    font-size: 26px; }
  .solr-search-header .views-exposed-form {
    position: relative;
    margin-bottom: 30px; }
    .solr-search-header .views-exposed-form .views-exposed-widget {
      padding: 0; }
      .solr-search-header .views-exposed-form .views-exposed-widget:first-child {
        width: 100%;
        padding-right: 106px; }
        @media screen and (max-width: 767px) {
          .solr-search-header .views-exposed-form .views-exposed-widget:first-child {
            padding-right: 50px; } }
      .solr-search-header .views-exposed-form .views-exposed-widget #edit-search-api-views-fulltext {
        border: 5px solid #dddddd;
        -webkit-box-shadow: none;
        box-shadow: none;
        height: 50px;
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        max-width: none; }
      .solr-search-header .views-exposed-form .views-exposed-widget.views-submit-button {
        position: absolute;
        right: 0;
        top: 0; }
        .solr-search-header .views-exposed-form .views-exposed-widget.views-submit-button #edit-submit-clinical-trial-search {
          width: auto;
          height: 50px;
          background-color: #e7751d;
          background-image: url(/sites/all/themes/wcmc_jcto/images/search-white.svg);
          background-size: 20px 20px;
          background-repeat: no-repeat;
          background-position: 15px center;
          border: none;
          color: #fff;
          -webkit-border-radius: 2px;
          border-radius: 2px;
          font-size: 16px;
          padding-left: 45px; }
          @media screen and (max-width: 767px) {
            .solr-search-header .views-exposed-form .views-exposed-widget.views-submit-button #edit-submit-clinical-trial-search {
              font-size: 0;
              padding-left: 30px; } }
      .solr-search-header .views-exposed-form .views-exposed-widget.views-reset-button {
        display: none; }

@media screen and (max-width: 767px) {
  div[class*="pane-facetapi-"] {
    display: none; } }

div[class*="pane-facetapi-"] .pane-title {
  margin-top: 0;
  font-size: 13px;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 5px; }

div[class*="pane-facetapi-"] .select2-container {
  min-width: 100%;
  max-width: 290px; }

.trial-categories-list .views-row {
  border-bottom: 1px solid #dddddd; }
  .trial-categories-list .views-row a {
    position: relative;
    display: inline-block;
    padding: 8px 30px 8px 0px;
    font-weight: 700; }
    .trial-categories-list .views-row a:after {
      content: '\e80d';
      color: #cf4520;
      position: absolute;
      right: 12px;
      top: 16px;
      font-size: 70%;
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      text-align: center;
      /* opacity: .8; */
      /* For safety - reset parent styles, that can break glyph codes*/
      font-variant: normal;
      text-transform: none;
      /* fix buttons height, for twitter bootstrap */
      line-height: 1em;
      /* Animation center compensation - margins should be symmetric */
      /* remove if not needed */
      /* you can be more comfortable with increased icons size */
      /* font-size: 120%; */
      /* Font smoothing. That was taken from TWBS */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      /* Uncomment for 3D effect */
      /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
      text-decoration: none; }

.pane-trial-categories-panel-pane-2 .hiddencats {
  display: none; }

.search-filter-refine {
  background: url(../images/jcto_filter_icon.png) no-repeat;
  background-size: 30px 30px;
  background-position: 10px center;
  font-weight: bold;
  margin-left: 0;
  margin-bottom: 15px;
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 35px; }
  .search-filter-refine:before {
    line-height: 2.5; }
  .search-filter-refine.filter-hidden {
    display: none; }
  .search-filter-refine:hover, .search-filter-refine:focus {
    background-color: #fff;
    color: #cf4520;
    outline: none; }
  .search-filter-refine:active {
    background: url(../images/jcto_filter_icon.png) no-repeat;
    background-size: 30px 30px;
    background-position: 10px center;
    box-shadow: none; }
  @media screen and (min-width: 768px) {
    .search-filter-refine {
      display: none; } }

.search-pages-sidebar {
  font-size: 13px; }
  .search-pages-sidebar h3 {
    text-align: left;
    font-size: 16px;
    color: #000000; }
  .search-pages-sidebar .panels-ipe-portlet-wrapper {
    float: none;
    width: 100%; }
  .search-pages-sidebar .views-field-field-categoryn, .search-pages-sidebar .views-field-title {
    float: left;
    width: 100%; }
  .search-pages-sidebar .view-id-clinical_trials .view-filters {
    display: none; }

.pane-current-search-clinical-trials {
  margin-bottom: 20px;
  border-bottom: 1px solid #dddddd; }
  .pane-current-search-clinical-trials .current-search-item.current-search-item-results {
    font-size: 20px;
    border-bottom: 1px solid #dddddd;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-bottom: 10px; }
    .pane-current-search-clinical-trials .current-search-item.current-search-item-results span {
      font-weight: 700;
      font-size: 22px; }
      .pane-current-search-clinical-trials .current-search-item.current-search-item-results span:last-child {
        color: #cf4520; }
  .pane-current-search-clinical-trials .current-search-item.current-search-item-active-items {
    display: inline-block; }
  .pane-current-search-clinical-trials .current-search-item.current-search-item-reset {
    display: inline-block;
    padding-left: 25px; }
  .pane-current-search-clinical-trials .current-search-item .refined-results {
    font-size: 14px; }
    .pane-current-search-clinical-trials .current-search-item .refined-results:before {
      content: 'Refined by: ';
      position: relative; }
    .pane-current-search-clinical-trials .current-search-item .refined-results li {
      display: inline-block;
      font-weight: 700; }
      .pane-current-search-clinical-trials .current-search-item .refined-results li a {
        position: relative;
        color: transparent;
        float: right;
        top: 2px;
        left: 2px; }
        .pane-current-search-clinical-trials .current-search-item .refined-results li a:before {
          display: inline-block;
          content: '';
          background-image: url(/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/close-circle.svg);
          background-size: 15px 15px;
          height: 15px;
          width: 15px;
          position: absolute;
          top: 2px; }

/**
 * Styles targeting various components
 *
 */
/* =Drawer Navigation
----------------------------------------------------------*/
@media screen and (min-width: 768px) {
  #drawer-nav .level-1 > .menu {
    display: flex;
    flex-flow: row wrap; } }

@media screen and (min-width: 768px) {
  #drawer-nav .level-2 {
    padding: 0 60px 20px 0;
    width: 33%; } }

/* =Default Select Elements
----------------------------------------------------------*/
.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid #dddddd;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px; }

/**
 * Styles related to Callout Boxes
 *
 */
/* =Callout Boxes
----------------------------------------------------------*/
.callout {
  margin: 40px 0;
  padding: 30px;
  border: 1px solid #dddddd; }

.callout__title {
  font-family: "1898Sans-Bold", sans-serif;
  font-size: 40px;
  line-height: 42px;
  text-align: center;
  margin: 20px 0;
  color: #555555; }

.callout__title a:after {
  content: '\e80d';
  color: #e7751d;
  padding-left: 10px;
  font-size: 60%;
  vertical-align: top;
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  text-decoration: none;
  line-height: 1.5; }

.callout__description {
  font-weight: 300;
  font-size: 20px;
  line-height: 32px;
  color: #666666;
  text-align: center;
  margin: 0 auto 30px; }

.callout__action {
  text-align: center; }

.callout__action .btn--wcm {
  font-size: 16px;
  width: auto;
  display: inline-block;
  padding: 6px 42px; }

/* =Tools Callouts
----------------------------------------------------------*/
.page-tools #main-content .panel-pane {
  margin: 0 0 30px;
  padding: 30px;
  border: 1px solid #dddddd; }
  .page-tools #main-content .panel-pane h3 {
    margin-top: 0; }

/**
 * Styles related to the Clinical Trial displays
 *
 */
/* =Trial Detail
 ----------------------------------------------------------*/
.node-type-clinical-trial .radix-layouts-sidebar {
  padding-bottom: 30px; }
  .node-type-clinical-trial .radix-layouts-sidebar h3, .node-type-clinical-trial .radix-layouts-sidebar .pane-title {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 15px; }

.pane-jcto-return-to-list .btn--wcm {
  margin: 0 0 30px;
  width: auto;
  padding: 10px 20px; }
  .pane-jcto-return-to-list .btn--wcm:before {
    content: '\e810'; }
  .pane-jcto-return-to-list .btn--wcm:hover:before {
    right: 0;
    left: 10%;
    top: 10%;
    text-align: left; }

.pane-node-field-ct-locations h3 {
  margin: 0 0 20px; }

.ct-location__title.element__toggle--open {
  background: #f7f7f7;
  border-color: #ffc72c;
  border-width: 2px 0 0; }

.ct-location__contacts {
  background: #f7f7f7;
  padding: 20px; }

.ct-location__image {
  overflow: hidden;
  margin: 0 0 20px; }

.ct-location__image a img {
  transition: all 0.3s ease 0s; }
  .ct-location__image a img:hover {
    transform: scale(1.1); }

.pane-node-field-ct-primary-investigators h3 {
  margin: 10px 0; }

.pane-node-field-protocolid h3,
.pane-node-field-sponsor-protocol-id h3,
.pane-node-field-clinicaltrials-gov-protoco h3 {
  font-size: 15px;
  font-weight: 400 !important;
  display: inline;
  color: #000; }

.pane-node-field-protocolid > div,
.pane-node-field-sponsor-protocol-id > div,
.pane-node-field-clinicaltrials-gov-protoco > div {
  display: inline;
  color: #000; }

/* =Trial Search
----------------------------------------------------------*/
.ct-search-teaser {
  margin: 0 0 25px; }

.ct-search-teaser__title {
  font-size: 17px;
  margin: 0 0 10px; }

.ct-search-teaser__details {
  margin: 10px 0; }

.trial-locations-btn {
  margin: 20px 0 30px; }
  .trial-locations-btn .btn--wcm {
    width: 100%; }
    .trial-locations-btn .btn--wcm:before {
      content: ''; }

/* =Featured Trial List
----------------------------------------------------------*/
.featured-trials-list {
  margin: 0 0 20px;
  padding: 0 0 20px;
  border-bottom: 1px solid #dddddd; }
  .featured-trials-list .btn--small {
    margin: 10px 0; }

/* =Featured Trial Sort
----------------------------------------------------------*/
.tabledrag-processed a {
  border: none; }

.tabledrag-processed > tbody > tr:nth-child(odd) > td,
.tabledrag-processed > tbody > tr:nth-child(odd) > th {
  background-color: #eee;
  border: none; }

#views-exposed-form-featured-trials-panel-pane-2 {
  margin: 20px 0 30px; }
  #views-exposed-form-featured-trials-panel-pane-2 .views-exposed-form label {
    display: none; }
  #views-exposed-form-featured-trials-panel-pane-2 .views-exposed-form .form-select {
    max-width: none;
    width: 300px; }

/**
 * Styles related to the Locations
 *
 */
/* =Location Detail
 ----------------------------------------------------------*/
.field-wcm-loc-image img {
  max-width: none;
  width: 100%; }

.wcm-location__meta > div {
  padding: 0 30px; }

.wcm-location__trial-cta {
  padding: 20px 0 0; }
  .wcm-location__trial-cta .btn--wcm {
    width: auto;
    padding: 10px 20px; }
    .wcm-location__trial-cta .btn--wcm:before {
      content: ''; }
    .wcm-location__trial-cta .btn--wcm:hover:before {
      top: 10%;
      content: ''; }

.location-featured-trials h2 {
  margin: 20px 0; }

.location-featured-trial__title {
  font-size: 13px;
  line-height: 18px;
  color: #000;
  border: 1px solid #ddd;
  border-width: 1px 0 0;
  cursor: pointer;
  margin: 0;
  padding: 15px 55px 15px 10px;
  font-weight: 600; }
  .location-featured-trial__title a {
    border: none; }
    .location-featured-trial__title a:after {
      content: '\e80d';
      color: #e7751d;
      padding-left: 10px;
      font-size: 60%;
      vertical-align: middle;
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      text-align: center;
      /* opacity: .8; */
      /* For safety - reset parent styles, that can break glyph codes*/
      font-variant: normal;
      text-transform: none;
      /* fix buttons height, for twitter bootstrap */
      line-height: 1em;
      /* Animation center compensation - margins should be symmetric */
      /* remove if not needed */
      /* you can be more comfortable with increased icons size */
      /* font-size: 120%; */
      /* Font smoothing. That was taken from TWBS */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      /* Uncomment for 3D effect */
      /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
      text-decoration: none;
      line-height: 1.5; }

.view-all-trials-cta .btn--wcm {
  margin: 0 0 30px;
  width: auto;
  padding: 10px 20px; }
  .view-all-trials-cta .btn--wcm:hover:before {
    top: 10%; }

/* =Location List
----------------------------------------------------------*/
.location-teaser {
  position: relative;
  margin: 0 0 30px;
  overflow: hidden; }
  .location-teaser > a {
    display: block;
    border: none; }

.location-teaser .mask {
  position: absolute;
  top: 15px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  z-index: 9;
  background: rgba(0, 0, 0, 0.6);
  border: 2px solid #dddddd; }

.location-teaser__title {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0%, -50%);
  text-align: center;
  font-family: "1898Sans-Bold", sans-serif;
  font-size: 28px;
  line-height: 1.3;
  color: #fff;
  z-index: 10; }

.location-teaser__title:after {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 0 8px 8px;
  border-color: transparent transparent transparent #e7751d;
  margin-left: 10px; }

.location-teaser__image img {
  transition: all 0.3s ease 0s; }

.location-teaser > a:hover .location-teaser__image img {
  transform: scale(1.1); }

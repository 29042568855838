/**
 * Styles related to the Clinical Trial displays
 *
 */

 /* =Trial Detail
 ----------------------------------------------------------*/

.node-type-clinical-trial {
  .radix-layouts-sidebar {
    padding-bottom: 30px;
    h3, .pane-title {
      font-family: $font-family-sans-serif;
      font-weight: 700;
      font-size: 15px;
    }
  }
}

.pane-jcto-return-to-list {
 .btn--wcm {
   margin: 0 0 30px;
   width: auto;
   padding: 10px 20px;
   &:before {
     content: '\e810';
   }
   &:hover {
     &:before {
       right: 0;
       left: 10%;
       top: 10%;
       text-align: left;
     }
   }
 }
}

.pane-node-field-ct-locations {
  h3 {
    margin: 0 0 20px;  
  } 
}

.ct-location__title.element__toggle--open {
  background: $wcm-bg-gray;
  border-color: $wcm-yellow;
  border-width: 2px 0 0; 
}

.ct-location__contacts {
  background: $wcm-bg-gray;
  padding: 20px;
}

.ct-location__image {
  overflow: hidden;
  margin: 0 0 20px;
}

.ct-location__image a img {
  transition: all 0.3s ease 0s;
  &:hover {
    transform: scale(1.1);
  }
}

.pane-node-field-ct-primary-investigators {
  h3 {
    margin: 10px 0;
  }
}

.pane-node-field-protocolid,
.pane-node-field-sponsor-protocol-id,
.pane-node-field-clinicaltrials-gov-protoco {
  h3 {
    font-size: 15px;
    font-weight: 400 !important;
    display: inline;
    color: #000;
  }
  > div {
    display: inline;
    color: #000;
  }
}

/* =Trial Search
----------------------------------------------------------*/

.ct-search-teaser {
  margin: 0 0 25px;
}

.ct-search-teaser__title {
  font-size: 17px;
  margin: 0 0 10px;
}

.ct-search-teaser__details {
  margin: 10px 0;
}

.trial-locations-btn {
  margin: 20px 0 30px;
  .btn--wcm {
    width: 100%;
    &:before {
      content: '';
    }
  }
}

/* =Featured Trial List
----------------------------------------------------------*/

.featured-trials-list {
  margin: 0 0 20px;
  padding: 0 0 20px;
  border-bottom: 1px solid $wcm-border-gray;
  .btn--small {
    margin: 10px 0;
  }
}

/* =Featured Trial Sort
----------------------------------------------------------*/

.tabledrag-processed {
  a {
    border: none;
  }
}

// Override for very specific style declared elsewhere
.tabledrag-processed>tbody>tr:nth-child(odd)>td,
.tabledrag-processed>tbody>tr:nth-child(odd)>th {
    background-color: #eee;
    border: none;
}

#views-exposed-form-featured-trials-panel-pane-2 {
  margin: 20px 0 30px;
  .views-exposed-form {
    label {
      display: none;
    }
    .form-select {
      max-width: none;
      width: 300px;
    } 
  }
}
 
/**
 * WCMC JCTO Subthemes
 *
 */
/* =Site-specific styles
----------------------------------------------------------*/

footer{
  padding-top: 0;
}

#primary-nav .level-1 {
  width: 25%;
}

#drawer-nav .level-2{
  //padding-right: 5px;
}

.main-content{
  margin-bottom: 20px;
}

.block-menu-block .content {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 15px;
    -moz-column-gap: 15px;
    column-gap: 15px;
    // height: 120px;
}

.cta-text{
	p {
	  font-size: 13px;
	  font-size: 1.3rem;
	  color: #413c3d;
	  margin-right: 10px; }
	  .lt-ie9 .cta-text p {
	    margin-right: 3em; }
	  @media screen and (min-width: 985px) {
	    .cta-text p {
	      margin-right: 3em; } }
	  .lt-ie9 .cta-text p {
	    margin-right: 25px; }
	  @media screen and (min-width: 600px) {
	    .cta-text p {
	      margin-right: 25px; } 
	 }
}

.go-cta {
  background-color: #B31B1B;
  border: 3px solid #E0E0E0;
  border-radius: 50%;
  color: #FFFFFF;
  float: right;
  font-weight: bold;
  height: 25px;
  left: -10px;
  padding: 12px 10px 8px 11px;
  position: relative;
  text-transform: uppercase;
  top: -57px;
  width: 25px; 
	&:hover{
    color: #FFFFFF;
    background-color: #9d1818;
    text-decoration: none;
		}
}


/*ROW2*/
.cta-home-row2-left {
  width: 49%;
  height: 300px;
  padding: 0%;
  text-align: center;
  display: block;
  float: left;
  clear: left; 
  img{
    width: 550px;
  }
}

.cta-home-row2-right p, .cta-home-row2-left p{
  margin-top: 11px;
  a{
    color: #69605d;
  }
}

.cta-home-row2-right {
  width: 49%;
  height: 300px;
  padding: 0%;
  text-align: center;
  display: block;
  float: right;
  clear: right; 
  img{
    width: 556px;
  }
}


/*ROW3*/
.homepage-news-image{
    margin: -18px 0px 26px 0px;
}

.view-display-id-panel_pane_5 {
  width: auto;
  height: auto;
  padding: 0%;
  margin-top: 2%;
  display: inline-block;
  text-align: left;
  margin-right: 1%;
  float: left; 
}



.cta-home-row3-left {
  width: 32.6%;
  height: 275px;
  padding: 0%;
  margin-top: 2%;
  display: inline-block;
  text-align: center;
  margin: 2% 1% 0% 0%;
  float: left;
  position: static; 
}

.cta-home-row3-center {
  width: 32.6%;
  height: 275px;
  padding: 0%;
  margin-top: 2%;
  display: inline-block;
  text-align: center;
  margin-right: 1%;
  float: left;
  position: static; }


.cta-home-row3-right {
  width: 32.6%;
  height: 275px;
  padding: 0%;
  margin-top: 2%;
  display: inline-block;
  text-align: center;
  float: left; 
}
 
.cta-home-row3-left p a, .cta-home-row3-center p a, .cta-home-row3-right p a{
  color: #7F7F7F!important;
  &:after{
    content: '\e80d';
    color: #e87722;
    padding-left: 10px;
    font-size: 60%;
    vertical-align: top;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-decoration: none;
    line-height: 2.8;
  }
}

.cta-home-row3-left p, .cta-home-row3-center p, .cta-home-row3-right p{
  margin-top: 26px;
}

/*Mobile and Tablet*/
@media screen and (max-width: 760px) {

  /*ROW2*/
  .cta-home-row2-left {
    width: 95%;
    height: 100%;
    padding: 2%;
    text-align: center;
    float: left; 
  }

  .cta-home-row2-right {
    width: 95%;
    height: 100%;
    padding: 2%;
    text-align: center;
    float: right;
    clear: right; 
  }

    /*ROW3*/
  .view-display-id-panel_pane_5 {
    width: 95%;
    height: 100%;
    padding: 2%;
    text-align: center;
    float: left; 
  }
      
  .cta-home-row3-left {
    width: 95%;
    height: 100%;
    padding: 2%;
    text-align: center;
    float: left; 
  }  

  .cta-home-row3-center {
    width: 95%;
    height: 100%;
    padding: 2%;
    text-align: center;
    float: left; 
  }

  .cta-home-row3-right {
    width: 95%;
    height: 100%;
    padding: 2%;
    text-align: center;
    float: left; 
  }

}


/* =============================================================================
~New JCTO trials styles
========================================================================== */

.form-submit, .ct_more_button, .ct_back_btn, .dndcinfo{
  border-radius: 5px;
  background-color:  $wcm-dark-orange;
  height: 40px;
  line-height: 20px;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 10px!important;
}

.ct_more_button, .ct_back_btn{
  float: left;
  color: #fff;
  padding: 0 10px;
  text-transform: capitalize;
  line-height: 30px;
  height: 30px;
  width: 100%;
  clear: both;
  margin: 12px 0 20px;
}

.dndcinfo{
  color: #fff;
  float: left;
  text-decoration: none;
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  margin: 10px 0 25px;
  &:hover{
    text-decoration: none;
    color: #fff;
    background-color: $wcm-bright-orange;
  }
}

.feat{
  color:  #ffffff;
  font-size: 13px;
  font-weight: 400;
  text-transform: none;
  padding: 0 6px;
}

.views-field-nothing{
  span.field-content{
    padding-left: 0px!important;
  }
}

.view-id-clinical_trials{
  div.field-content{
    a{
      color: #69605d;
    }
  }
}

ul.pager{
  float: left;
  li{
    margin-left: 0px;
    font-size: 15px;
    font-weight: 400;
    a{
      color:  #b31b1b;
      text-align: left;

    }
  }
  li.pager-ellipsis{
    display: none;
  }
}

.radix-layouts-sidebar h3{
  color: #000;
  font-size: 16px;
  margin-top: 15px;
}

.radix-layouts-sidebar{
  .pane-entity-field .field {
      margin-bottom: 0;
  }
  ul.links{
    margin-bottom: 0;
  }
}


.pane-uuid-f93e31c7-9f76-43dc-9f0e-624bc7bc64f9{
  float: left;
  width: 100%;
  h2{
    float: left;
    clear: both;
  }
  p{
    float: right;
    .ct_back_btn{
      float: right;
      &:hover{
        background-color: $wcm-bright-orange;
        text-decoration: none;
        color: #fff;
      }
    }
  }
}
.pane-clinical-trials-panel-pane-1{
  margin-left: 24px;
  .views-field-title {
    margin: 10px 0 7px;
    .field-content{text-decoration: none!important;}
    a{
      font-size: 18px; 
      font-weight: 400;
      line-height: 26px;
    }
  }
  .views-field{
    float: left;
    width: 100%;
    .views-label{
      float: left;
      font-size: 15px;
      font-weight: bold;
      line-height: 22px;
    }
    .field-content{
      float: left;
      font-size: 15px;
      line-height: 22px;
      padding-left: 3px;
    }
  }
  .views-field-title{
    .field-content{
      padding-left: 0px;
    }
  }
  .views-field-view-node{
    .field-content{
      padding-left: 0px;
    }
  }
}

.pane-views-exp-clinical-trials-page{
  .views-exposed-widget{
    padding-top: 22px;
  }

  select{
    max-width: 540px;
    width: 100%;
    height: 50px;
    color:  #301d1d;
    font-size: 15px; 
    line-height: 50px; 
    text-align: left;
    background: url('../images/arrowdown2x.png') no-repeat right 14px top 9px;
    background-size: 31px 30px;
    padding-right: 20px;
    box-sizing: border-box;
    border: 1px solid #aeaaaa;
    border-radius: 4px!important;
    -webkit-appearance:none;
    -moz-appearance: none;
    padding-left: 8px;
    border: 1px solid #ddd
  }
  label[for=edit-field-categoryn-tid]{
    display: none;
  }

  // label[for='edit-combine']{
  //   font-size: 20px;
  //   text-transform: none;
  //   font-family: "1898Sans-Bold",sans-serif;
  //   font-weight: normal;
  //   line-height: 1.1;
  //   color: #b31b1b;
  // }

  label{
    font-size: 16px; 
    font-weight: 400;
    line-height: 20px;
  }

  .asearch{
    margin-bottom: 17px;
    margin-top: 22px;
    float: left;
    width: 100%;
    cursor: pointer;
  }
}

.shs-select-level-2{
  margin-top: 25px;
  margin-bottom: 25px;
}

.pane-views-exp-clinical-trials-page-1{

  label{
    font-size: 13px;
    margin-top: 22px;
  }

  label[for=edit-field-drugs-device-value]{
    margin-top: 0;
  }

  select{
    max-width: 540px;
    width: 100%;
    height: 50px;
    color:  #301d1d;
    font-size: 15px; 
    line-height: 50px; 
    text-align: left;
    background: url('../images/arrowdown2x.png') no-repeat right 14px top 9px;
    background-size: 31px 30px;
    padding-right: 20px;
    box-sizing: border-box;
    border: 1px solid #aeaaaa;
    border-radius: 4px!important;
    -webkit-appearance:none;
    -moz-appearance: none;
    padding-left: 8px;
    border: 1px solid #ddd
  }

  #edit-field-categoryn-tid-wrapper{
    padding-top: 0px;
  }

  #edit-combine{
    background-image: none;
  }

  .views-exposed-widget{
    padding: 0px;
    float: left;
    clear: both;
  }

  .views-widget-filter-field_study_type_tid{
    width: 100%;
  }
  .views-widget-filter-field_categoryn_tid{
    display: none;
  }

}

.btmbar{
  border-bottom: 1px solid #E7E4E0;
  width: 100%;
  display: block;
}

#edit-combine-wrapper,
#edit-field-categoryn-tid-wrapper, 
.views-widget-filter-field_drugs_device_value, 
.views-widget-filter-field_phase_value,
.views-widget-filter-field_study_type_tid,
.views-widget-filter-shs_term_node_tid_depth{
  width: 100%;
}
#edit-combine{
  max-width: 540px;
  height: 50px;
  line-height: 50px;
  width: 100%;
  background: url('../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/search.svg') no-repeat;
  background-position: right 11px top 11px;
  background-size: 27px 27px;
  &::-webkit-input-placeholder{
    color:  #301d1d;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color:  #301d1d;
  }
  &::-moz-placeholder {  /* Firefox 19+ */
    color:  #301d1d;
  }
  &:-ms-input-placeholder {  
    color:  #301d1d; 
  }
}


#views-exposed-form-clinical-trials-page h3{
  margin-bottom: 15px;
  margin-top: 5px;
}




.CT-form-footer{
  float: left;
  border-top: 1px solid #E7E4E0;
  width: 100%;
  padding-top: 15px;
  margin-top: 12px;
  .views-exposed-widget{
    padding-top: 0px;
    input{
      margin-top: 0px!important;
    }
  }
  p{
    line-height: 40px; 
    height: 40px;
    text-align: left;
    margin: 0;
  }
}

//hiding form fields on load 

#edit-field-primary-investigator-name-value-wrapper, #edit-field-status-tid-wrapper{
  display: none;
}

#edit-field-categoryn-tid-wrapper{
  padding-top: 28px;
}


/* --- SORT - Hiding Filters ---*/
.pane-views-exp-clinical-trials-page-2{
  .views-widget-filter-field_categoryn_tid, 
  .views-widget-filter-field_sponsor_tid,
  .views-widget-filter-combine,
  .views-widget-filter-field_study_type_tid,
  .views-widget-filter-field_drugs_device_value,
  .views-widget-filter-field_phase_value,
  .views-widget-filter-shs_term_node_tid_depth,
  .asearch{
    display: none;
  }
  .viewalllink{
    display: none;
  }

  .views-widget-sort-by{
    float: left;
    width: 100%;
    height: 39px;
    background-color: #EFEFEF;
    color: #000;
    padding-top: 0px;
    label{
      float: left;
      text-transform: uppercase;
      padding: 0;
      margin: 0;
      font-size: 15px;
      line-height: 39px;
      font-weight: 400;
      padding:0 20px 0 24px;
    }

    a{
      float: left;
      color: #000;
      font-size: 15px;
      line-height: 39px;
      padding: 0 20px;
    }

    a.active{
      font-weight: bold;
    }
  }

  .views-widget-sort-order{
    padding-top:1em;

    label[for="edit-sort-order"]{
      display: none;
    }
    
    a{
      color: #000;
      font-size:14px;
    }
    
    a.active{
      font-weight: bold;
    }

    #edit-sort-order-asc{
      padding:0 13px 0 24px;
      border-right: 1px solid #E7E4E0;
      float: left;
    }
    #edit-sort-order-desc{
      float: left;
      padding-left: 9px;
    }


  }

  .CT-form-footer{
    margin-left: 3.4458%;
    width: (100% - 3.4458%)
  }
}

#views-exposed-form-clinical-trials-page-1{
  .views-widget-sort-by{
    display: none;
  }
  .viewalllink{
    display: none;
  }
  .orfil{
    display: none;
  }

  .views-widget-filter-combine{
    label[for='edit-combine']{
      display: none;
    }
  }
}

.field-name-field-key-eligibility{
  div.field-label{
    text-transform: uppercase;
    font-size: 16px; 
    font-weight: 400;
    line-height: 22px; 
    color: #301d1d;
    border-top: 1px solid #E3E4E5;
    padding-top: 10px;
    margin-top: 10px;
  }
}

.radix-moscone {
  .col-md-9{
    border-left: 1px solid #E7E4E0;
  }
}

.pane-clinical-trials-panel-pane-2{
  float: left;
  padding-bottom: 4px;
  margin-bottom: 14px;
  border-bottom: 1px solid #E7E4E0;
}

#views-exposed-form-clinical-trials-page{
  #edit-field-study-type-tid-wrapper,
  #edit-field-phase-value-wrapper,
  #edit-field-age-group-value-wrapper,
  #edit-field-drugs-device-value-wrapper{
      display: none;
      width: 100%;
  }
}



.pane-entity-field{
  .field {
    margin-bottom:20px;
}
  .field-label{
    font-size:16px;
    font-weight:normal; 
    text-transform:uppercase;
    margin-bottom:3px;
  }
}


//New
.pane-views-exp-clinical-trials-page-1{
  .asearch{
    display: none;
  }
  .views-widget-filter-field_sponsor_tid_1, 
  .views-widget-filter-field_keywords_tid,
  .views-widget-filter-field_categoryn_tid_1{
    display: none;
  }
}

.views-submit-button{
  input.form-submit{
    position: relative;
    width: 220px;
    margin: 0 auto;
    border-radius: 20px;
    border-width: 4px;
    font-size: 13px;
    color: #cf4520;
    background-color: transparent;
    border: 4px solid $wcm-bright-orange;
    padding: 6px 20px;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    line-height: 1.666666667;
    -webkit-user-select: none;
    &:hover{
      color: #fff;
      background-color: #e87722;
      border-color: #cf4520;
    }
  }
}

.views-exposed-form .views-exposed-widget .form-submit {
    margin-top: 0;
}

.views-reset-button{
  .form-submit{
    color: $wcm-dark-orange;
    position: relative;
    display: block;
    width: 220px;
    margin: 0 auto;
    border-radius: 20px;
    border-width: 4px;
    font-size: 13px;
    color: #cf4520;
    background-color: transparent;
    border: 4px solid $wcm-bright-orange;
    padding: 6px 20px;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    line-height: 1.666666667;
    -webkit-user-select: none;
    &:hover{
      color: #fff;
      background-color: #e87722;
      border-color: #cf4520;
    }
  }
}

/* Text area */
div.caption{
  padding-bottom: 0;
  margin-bottom: 0;
  margin-top: 0;
  div.caption-inner{
    border: none;
    padding-left: 0px;
    padding-bottom: 0;
    margin-bottom: 0;
    img{

    }
    p{
      color:  #69605d;
      font-size: 14px; 
      font-weight: 400;
      line-height: 18px; 
      text-align: left;
      padding-bottom: 0;
    margin-bottom: 0;
    }
  }
}
.view-display-id-panel_pane_1{
  .view-footer{
    display: none;
  }
}

.norestitle{
  color: #301d1d;
  font-size: 25px;
  font-weight: 400;
  line-height: 32px;
  text-transform: none;
  margin-left: 24px;
  width: 100%;
  float: left;
}

.pane-views-exp-clinical-trials-page-2 .ctools-auto-submit-click{
  display: none;
}


.field-name-body img{
  padding: 10px;
}

#user-login a{
  font-size: 13.6px;
}

.cycle-prev, .cycle-next{
  background-image: none;
}

//#views-exposed-form-clinical-trials-page #edit-field-study-type-tid-wrapper,
#views-exposed-form-clinical-trials-page #edit-field-phase-value-wrapper,
#views-exposed-form-clinical-trials-page #edit-combine-wrapper,
//#views-exposed-form-clinical-trials-page #edit-field-drugs-device-value-wrapper,
#views-exposed-form-clinical-trials-page #edit-field-phase-auto-tid-wrapper{
  display: none; }

@media screen and (min-width: 985px) {
  #views-exposed-form-clinical-trials-page #edit-field-phase-auto-tid-wrapper{
    width: 100%;
  }
}
.pane-views-exp-clinical-trials-page .asearch {
  display: none;
  margin-bottom: 17px;
  margin-top: 22px;
  float: left;
  width: 100%;
  cursor: pointer; }
.pane-views-exp-clinical-trials-page-1 .asearch {
  display: none; }
.pane-views-exp-clinical-trials-page-1 .views-widget-filter-field_sponsor_tid_1,
.pane-views-exp-clinical-trials-page-1 .views-widget-filter-field_keywords_tid,
.pane-views-exp-clinical-trials-page-1 .views-widget-filter-field_categoryn_tid_1 {
  display: none; }

.information-sidebar .view-id-clinical_trials .view-filters {
    display: none;
}

.information-sidebar{
  font-size: 13px;
  .panels-ipe-portlet-wrapper{
    float: left;
    width: 100%;
  }
  .views-field-field-categoryn, .views-field-title{
    float: left;
    width: 100%;
  }
}

.pane-views-exp-clinical-trials-page-2 .views-widget-filter-field_categoryn_tid, 
.pane-views-exp-clinical-trials-page-2 .views-widget-filter-field_sponsor_tid, 
.pane-views-exp-clinical-trials-page-2 .views-widget-filter-combine, 
.pane-views-exp-clinical-trials-page-2 .views-widget-filter-field_study_type_tid, 
.pane-views-exp-clinical-trials-page-2 .views-widget-filter-field_drugs_device_value, 
.pane-views-exp-clinical-trials-page-2 .views-widget-filter-field_phase_value, 
.pane-views-exp-clinical-trials-page-2 .views-widget-filter-shs_term_node_tid_depth, 
.pane-views-exp-clinical-trials-page-2 .views-widget-filter-field_phase_auto_tid, 
.pane-views-exp-clinical-trials-page-2 .views-widget-filter-field_age_group_value,
.pane-views-exp-clinical-trials-page-2 .asearch,
.pane-views-exp-clinical-trials-page-1 .views-widget-sort-order,
.pane-views-exp-clinical-trials-page-1 .views-widget-sort-by {
  display: none;
}

.pane-views-exp-clinical-trials-page-1 .select2-container {
   max-width: 291px;
   min-width: 291px;
   margin-bottom: 25px;
}

.pane-views-exp-clinical-trials-page{
  .select2-container{
    min-width: 78%;
    margin-bottom: 25px;
  }
}

.ui-autocomplete-input{
  width: 100%;
  display: block;
  margin: 10px 0;
  border: 1px solid #777;
}
.ui-autocomplete{
  background-color: #eee;
  .lookup-term{
    font-weight: bold;
  }
}

.information-column h3{
  text-align: left;
  font-size: 16px;
  color: #000;
}

.information-column .feat{
  width: 100%;
}

.information-column h4{
  text-align: left;
}

.contact-info{
  p{
    text-align: left;
  }
}
.information-column .pane-clinical-trials-panel-pane-2 {
  a{
    &:after {
      content: "";
      display: inline-block;
      position: relative;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 5px solid #e67730;
      margin-left: 5px;
    }
  }
  a.dndcinfo{
     &:after {
      display: none;
     }
  }
}
.view-id-news.view-display-id-panel_pane_1 figure{
  float: left;
  width: 20%;
  margin: 18px 10px 0 0;
  img{
    width: 100%;
    max-width: 100%;
  }
  figcaption{
    float: left;
    width: 100%;
    text-align: left;
  }
}

.view-id-news.view-display-id-panel_pane_1 .view-teaser{
  margin-bottom: 10px;
}

#thumbnail-pager a>img{
  max-width: none;
  width: 146.5px;
  height: 82.6px;
}

.thumbnail-controller .pager{
  margin: 30px 0;
}

#two{
  h3{
    a{
      &:after{
        line-height: 1.9;
      }
    }
  }
}

#three{
  h3{
    margin: 20px 0 10px;
    a{
      &:after{
        line-height: 1.9;
      }
    }
  }
}

.slide-image{
  max-height: 460px;
  overflow: hidden;
}

.view-carousel .cycle-slideshow .carousel-slide .slide-text{
  background-color: #fff;
  padding: 15px 0;
  opacity: 0.85;
  p{
    padding-top: 18px;
    padding-right: 10px;
    color: #000;
    a{
      color: #e87722;
    }
  }
  h2 a{
    color: #000;
  }
}

.view-carousel .thumbnail-controller .thumbnail-controller-wrap .thumbnail-controller-wrap-inner #thumbnail-pager a>img {
  width: 120px;
  height: auto;
}

.brand__lockup img{
  max-width: 456px;
  display: block;
  margin: 0 auto;
}

.ctools-toggle{
  display: none;
}

.ctools-collapsible-container{
  h2.pane-title{
    position: relative;
    font-size: 13px;
    line-height: 18px;
    color: #000;
    border: 1px solid #ddd;
    border-width: 1px 0 0;
    cursor: pointer;
    margin: 0;
    padding: 15px 55px 15px 10px;
    font-weight: 600;
    font-family: "open-sans", sans-serif;
    &:hover{
      background: #f7f7f7;
      color: #e87722;
    }
    &:after{
      content: '-';
      color: #cf4520;
      width: 50px;
      display: block;
      position: absolute;
      top: 15px;
      right: 0;
      font-size: 30px;
      font-weight: 600;
      text-align: center;
      border-left: 1px solid #ddd;
    }
  }
}

.ctools-collapsible-content{
  padding: 0 50px 0 10px;
}

.ctools-collapsed h2.pane-title{
  color: #cf4520;
  &:after{
      content: '+';
  }
}

.node-clinical-trial h1 {
    float: left;
    width: 100%;
}
.pane-node-body h4{
  margin-bottom: 20px;
}
/* New Table Styles */

.conttable>thead>tr>th, .conttable>thead>tr>td, 
.conttable>tbody>tr>th, .conttable>tbody>tr>td, 
.conttable>tfoot>tr>th, .conttable>tfoot>tr>td {
    border: 1px solid #767677;
}

.conttable tbody>tr>td, .conttable tbody>tr>th, .conttable, table.conttable > tbody > tr:nth-child(odd) > td{
  border-right: none;
  border-left: none;
}

table {
    width: 100%;
    border: 1px solid #777;
}

table>thead>tr>th, 
table>thead>tr>td, 
table>tbody>tr>th, 
table>tbody>tr>td, 
table>tfoot>tr>th, 
table>tfoot>tr>td{
  border: 1px solid #777;
  padding: 11px;
}

table>tbody>tr:nth-child(odd)>td, table>tbody>tr:nth-child(odd)>th{
  background-color: #fff;
  border: 1px solid #777;
}

table>tbody>tr:first-child>td{
  border-top: 1px solid #dbdcdd;
}

table.noheader>tbody>tr:first-child>td{
  border-top: 1px solid #777;
}

.pane-node-title{
  h1{
    margin-bottom: 20px;
  }
}

.panel-pane.pane-custom.pane-1{
  width: 100%;
  float: left;
}

a.noafter{
  &:after{
    display: none!important;
  }
}

/* =============================================================================
   ~Diagrams
   ========================================================================== */

#investigator-diagram {
  margin: 0 0 20px;
  position: relative;
  img{
    width: 100%;
    margin-bottom: 20px;
  }
}

.diagram-link {
  display: block;
  position: absolute;
}

.contracts {
  width: 17.08333%;
  height: 20.3703%;
  top: 28.808%;
  left: 9.79%;
}

.study-activation {
  width: 28.7234%;
  height: 33.3333%;
  top: 16.2962%;
  left: 28.2978%;
}

.finance {
  width: 17.0212%;
  height: 20.3703%;
  top: 57.1923%;
  left: 18.2978%;
}

.integrity {
  width: 17.0212%;
  height: 20.3703%;
  top: 50%;
  left: 57.0212%;
}


.cycle-slideshow{
  @include breakpoint($md){
    max-height: 460px;
  }
  @include breakpoint($lg){
    max-height: none;
  }
}

#edit-submit-clinical-trials{
  background-image: url("../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/submit.svg");
  background-repeat: no-repeat;
  background-position: right 11px top 3px;
  background-size: 27px 27px;
  &:hover{
    background-image: none;
  }
}

#edit-reset, .views-reset-button>.form-submit{
  background-image: url("../images/reset-icon.svg");
  background-repeat: no-repeat;
  background-position: right 11px top 3px;
  background-size: 27px 27px;
  &:hover{
    background-image: none;
  }
}

h2.tagline{
  font-size: 15px;
  color: #818182;
  text-align: center;
  width: 100%;
  margin: 25px 0 23px;
  font-family: "open-sans", sans-serif;

}

.pane-node-field-protocolid{
  .field-label{
    text-transform: none;
    font-weight: bold;
  }
}

.pane-node-field-featured-image.news-featured-image{
  .hero-image img{
    width: auto;
  }
  figcaption{
    float: none;
    width: auto;
    text-align: left;
  }
}

span[aria-labelledby = "select2-edit-shs-term-node-tid-depth-select-4-container"]{
  display: none!important;
}


.pane-trial-categories-panel-pane-1{
  .hiddencats{
    display: none;
  }
}

a.dison{
  color: #000;
  font-weight: bold;
  font-size: 18px;
}

label[for=edit-shs-term-node-tid-depth]{
  font-weight: bold;
}

.pane-node-field-protocolid, 
.pane-node-field-sponsor-protocol-id,
.pane-node-field-clinicaltrials-gov-protoco{
  h3{
    color: grey;
    margin-top: 10px;
  }
}

.select2-container--default .select2-results>.select2-results__options{
  max-height: 367px;
}

/* Styles for SOLR Search */
.view-clinical-trial-search {
  .views-field-title {
    margin: 10px 0 7px;
    .field-content{text-decoration: none!important;}
    a{
      font-size: 18px; 
      font-weight: 400;
      line-height: 26px;
    }
  }
  .views-field{
    float: left;
    width: 100%;
    .views-label{
      float: left;
      font-size: 15px;
      font-weight: bold;
      line-height: 22px;
    }
    .field-content{
      float: left;
      font-size: 15px;
      line-height: 22px;
      padding-left: 3px;
    }
  }
  .views-field-title{
    .field-content{
      padding-left: 0px;
    }
  }
  .views-field-view-node{
    .field-content{
      padding-left: 0px;
    }
  }
}


.solr-search-header {
  background-color: $wcm-bg-gray;
  margin-bottom: 20px;

  .pane-node-title h1 {
    color: $wcm-black;
    font-size: 26px;
  }

  .views-exposed-form {
    position: relative;
    margin-bottom: 30px;

    .views-exposed-widget {
      padding: 0;

      &:first-child {
        width: 100%;
        @include breakpoint($xs) {
          padding-right: 50px;
        }
        padding-right: 106px;
      }

      #edit-search-api-views-fulltext {
        border: 5px solid $wcm-border-gray;
        @include box-shadow(none);
        height: 50px;
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        max-width: none;
      }

      &.views-submit-button {
        position: absolute;
        right: 0;
        top: 0;

        #edit-submit-clinical-trial-search {
          width: auto;
          height: 50px;
          background-color: $wcm-bright-orange;
          
          background-image: url(/sites/all/themes/wcmc_jcto/images/search-white.svg);
          background-size: 20px 20px;
          background-repeat: no-repeat;
          background-position: 15px center;
          border: none;
          color: $wcm-white;
          -webkit-border-radius: 2px;
          border-radius: 2px;
          font-size: 16px;
          padding-left: 45px;
          @include breakpoint($xs) {
            font-size: 0;
            padding-left: 30px;
          }
        }
      }

      &.views-reset-button {
        display: none;
      } 
    }
  }
}

div[class*="pane-facetapi-"] {

  @include breakpoint($xs) {
    display: none;
  }

  .pane-title {
    // border: 1px solid $wcm-border-gray;
    // padding: 15px 15px 13px 15px;
    margin-top: 0;
    // color: $wcm-dark-orange;
    font-size: 13px;
    font-weight: bold;
    font-family: $font-family-sans-serif;
    margin-bottom: 5px;
  }
  .select2-container {
    min-width: 100%;
    max-width: 290px;
  }
}
.trial-categories-list {
  .views-row {
    border-bottom: 1px solid $wcm-border-gray;

    a {
      position: relative;
      display: inline-block;
      padding: 8px 30px 8px 0px;
      font-weight: 700;

      &:after {
          content: '\e80d';
          color: $wcm-dark-orange;
          position: absolute;
          right: 12px;
          top: 16px;
          font-size: 70%;
          @include fontello();
          text-decoration: none;
      }
    }
  }

}

.pane-trial-categories-panel-pane-2 {
  .hiddencats{
    display: none;
  }
}

.search-filter-refine {

  &:before {
    line-height: 2.5;
  }

  &.filter-hidden {
    display: none;
  }

  background: url(../images/jcto_filter_icon.png) no-repeat;
  background-size: 30px 30px;
  background-position: 10px center;
  font-weight: bold;
  margin-left: 0;
  margin-bottom: 15px;
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 35px;

  &:hover, &:focus {
    background-color: $wcm-white;
    color: $wcm-dark-orange;
    outline: none;
  }

  &:active {
    background: url(../images/jcto_filter_icon.png) no-repeat;
    background-size: 30px 30px;
    background-position: 10px center;
    box-shadow: none;
  }

  @include breakpoint($sm) {
    display: none;
  }
}

.search-pages-sidebar {
  font-size: 13px;

  h3 {
    text-align: left;
    font-size: 16px;
    color: $wcm-black;
  }

  .panels-ipe-portlet-wrapper{
    float: none;
    width: 100%;
  }
  .views-field-field-categoryn, .views-field-title{
    float: left;
    width: 100%;
  }

  .view-id-clinical_trials .view-filters {
    display: none;
  }
}

.pane-current-search-clinical-trials {
  margin-bottom: 20px;
  border-bottom: 1px solid $wcm-border-gray;

  .current-search-item {

    &.current-search-item-results {
      font-size: 20px;
      border-bottom: 1px solid $wcm-border-gray;
      padding-bottom: 10px;
      padding-top: 10px;
      margin-bottom: 10px;

      span {
        font-weight: 700;
        font-size: 22px;

        &:last-child {
          color: $wcm-dark-orange;
        }
      }
    }

    &.current-search-item-active-items {
      display: inline-block;
    }
    &.current-search-item-reset {
      display: inline-block;
      padding-left: 25px;
    }

    .refined-results {
      font-size: 14px;
      &:before {
        content: 'Refined by: ';
        position: relative;
      }
      li {
        display: inline-block;
        font-weight: 700;

        a {
          position: relative;
          color: transparent;
          float: right;
          top: 2px;
          left: 2px;
          &:before {
            display: inline-block;
            content: '';
            background-image: url(/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/close-circle.svg);
            background-size: 15px 15px;
            height: 15px;
            width: 15px;
            position: absolute;
            top: 2px;
          }
        }
      }
    }
  }
}